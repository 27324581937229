






























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { DashboardViewModel } from '../viewmodels/dashboard-viewmodel'
import { walletStore } from '@/stores/wallet-store'
@Observer
@Component({})
export default class AutocompleteWallet extends Vue {
  @Prop({ required: true, default: null }) vm!: DashboardViewModel
  searchText = ''
  wallet = walletStore
  onTrack() {
    this.vm.addFirstWallet(this.searchText)
  }
  changeInput(event) {
    this.searchText = event
    this.vm.registerWalletError = ''
  }
}
